import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CustomLink,
  CTimeLineMedia,
  CBorderTitle,
} from '../../../components/_index';
import { title } from 'process';
import '../../../assets/_sass/page/example.scss';
import { useMobile } from '../../../utils/use-series';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'how to stay',
            ja: '過ごし方',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/todo/example/kv.png',
              },
              imgSp: {
                src: '/assets/images/todo/example/kv__sp.png',
              },
            },
          ],
        }}
        exClass="c_jumbotronWrapper__large"
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '過ごす',
                path: '/experience/',
              },
            ],
            current: {
              label: '過ごし方',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 p_exampleWrap">
        <LWrap>
          <h2 className="c_headingLv2">
            自然の中で
            <br className="u_sp" />
            ゆったり過ごす3日間
          </h2>
          <p className="c_sectLead">
            杜の都のリゾートの開放感と
            <br className="u_pc" />
            心が躍るおもてなしに日々の疲れが癒される。
            <br />
            また訪れたくなる、他では味わえない
            <br className="u_pc" />
            ここだけの特別な時間を思うがままにおたのしみください。
          </p>
          <CBorderTitle
            title={
              <>
                DAY<span>1</span>
              </>
            }
          />
          <CTimeLineMedia
            data={[
              {
                time: '15:00',
                title: 'ホテルについたら、ちょっと一息',
                text: (
                  <>
                    移動の疲れを癒すひとときは、ガーデンが目の前に広がるフォンテーヌで。ゆったりと座れるソファーや季節ごとにかわるスイーツなどと一緒に、くつろぎのひとときを。
                    <br />
                    <a href="/restaurants/fontaine/">
                      ロビーラウンジ「フォンテーヌ」
                    </a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example.png',
                  alt: '',
                },
              },
              {
                time: '16:00',
                title: 'ゆったり、お散歩',
                text: 'ひとやすみをしたら、ホテルの散策に。世界各国から集まる骨董品やインテリアに囲まれたこの空間こそが、非日常。いるだけでわくわくするような空間をお散歩する、ゆったりした時間すらも、旅の目的のひとつ。',
                img: {
                  src: '/assets/images/todo/example/img_example02.png',
                  alt: '',
                },
              },
              {
                time: '17:30',
                title: '素材が活きる東北の味を堪能',
                text: (
                  <>
                    シェフが選んだ、東北・宮城を中心とした食材の数々。旨みがつまった素材の味をそのままにたのしむ。目の前で調理される臨場感とシェフとの会話までたのしい鉄板焼「七つ森」で過ごす、贅沢ディナータイム。
                    <br />
                    <a href="/restaurants/nanatsumori/">鉄板焼「七つ森」</a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example03.png',
                  alt: '',
                },
              },
              {
                time: '20:00',
                title: '夜空を見上げ、ゆったりと天体観測',
                text: (
                  <>
                    ホテルの人気アクティビティ「宙ネタリウム」。夜空にきらめく四季折々の星や星座を観察。いそがしい日常を忘れて、星のソムリエ&#174;の資格をもつホテルスタッフの解説を聞きながら、仙台の夜をたのしむ。
                    <br />
                    <a href="/experience/activity/kami79c6m/">宙ネタリウム</a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example04.png',
                  alt: '',
                },
              },
            ]}
          />
          <CBorderTitle
            title={
              <>
                DAY<span>2</span>
              </>
            }
          />
          <CTimeLineMedia
            data={[
              {
                time: '8:00',
                title: 'お気に入りの場所で、ピクニック気分',
                text: (
                  <>
                    天気がいいときは、ガーデンでピクニックしながらの朝食。バスケットに詰めて、お好きな場所にシートを広げて、美味しい料理を堪能する。一日のはじまりにぴったりな素敵な朝の過ごしを、ぜひ。
                    <br />
                    <a href="/experience/activity/2yvt3kqzc/">ピクニック</a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example05.png',
                  alt: '',
                },
              },
              {
                time: '10:00',
                title: '綺麗な景色と街並みを満喫',
                text: (
                  <>
                    ホテル周辺は綺麗な街並みがつづき、季節によって表情を変える自然がたっぷりなのも魅力。サイクリングしながらたのしむ周辺観光では、ふらっと寄ったカフェでのおいしいコーヒー、初めて見る綺麗な植物・・・。行く先々での新たな出会いもたのしむ。
                    <br />
                    <a href="/experience/activity/4f3nsye9zzo/">
                      レンタルサイクル
                    </a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example06.png',
                  alt: '',
                },
              },
              {
                time: '14:00',
                title: '日頃の疲れを癒す、自分へのごほうび時間',
                text: (
                  <>
                    インド伝承医学の「アーユルヴェーダ」を取り入れたトリートメントエステは、心と身体のバランスを整え、健康的に美しく。旅の合間に、日頃頑張っている自分へのごほうび時間を。
                    <br />
                    <a href="/aesthetic/">
                      エイジアンエステティックス「アーユソーマ」
                    </a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example07.png',
                  alt: '',
                },
              },
              {
                time: '18:00',
                title: '「粋」を愉しむ至福のひととき',
                text: (
                  <>
                    2日目のディナーは、ホテル最上階からの景色が美しい中国料理「桂花苑」へ。定番からオリジナリティ溢れる料理まで、つい色々食べてみたくなるような料理の数々。まるで香港にいる雰囲気を感じる店内で、粋な中国料理を愉しむ。
                    <br />
                    <a href="/restaurants/keikaen/">中国料理「桂花苑」</a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example08.png',
                  alt: '',
                },
              },
              {
                time: '21:00',
                title: '足をのばして、ゆったりと過ごす',
                text: '広々とした空間で、リラックスタイム。バスソルトの香りで癒されたり、ちょっとお洒落なドリンクを持ち込んでみたり、、、。一日の終わりに、とことん自分を労る安らぎの時間。',
                img: {
                  src: '/assets/images/todo/example/img_example09.png',
                  alt: '',
                },
              },
            ]}
          />
          <CBorderTitle
            title={
              <>
                DAY<span>3</span>
              </>
            }
          />
          <CTimeLineMedia
            data={[
              {
                time: '8:15',
                title: '朝のガーデンでエネルギーをチャージ',
                text: (
                  <>
                    心地よい風、小鳥のさえずりが聞こえてくるような朝のガーデン。清々しいガーデンでのお散歩をたのしんだあとは、ガーデンでたのしむモーニングヨガ。全身の力を抜いて、深く息を吐き、吸い込む。心も身体もすっきりさせるリフレッシュタイムに。
                    <br />
                    <a href="/experience/activity/dqags4obj/">モーニングヨガ</a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example10.png',
                  alt: '',
                },
              },
              {
                time: '9:30',
                title: '東北の「美味しい」を、ぎゅっと堪能',
                text: (
                  <>
                    山と海に囲まれ、自然豊かな環境で育った東北の美味しい食材たち。ライブキッチンでシェフがつくるできたての料理。朝から気分が上がる、たのしい朝食で、気持ちのいい一日のスタートを。
                    <br />
                    <a href="/restaurants/breakfast/">朝食</a>
                  </>
                ),
                img: {
                  src: '/assets/images/todo/example/img_example11.png',
                  alt: '',
                },
              },
              {
                time: '11:00',
                title: '荷物をあずけて、身軽にショッピング',
                text: 'チェックアウトのあとは、近隣の「泉パークタウン タピオ」や「仙台泉プレミアム・アウトレット」などでショッピングも。荷物はホテルに預けて、身軽に周れるのが嬉しい。また来たくなるような美しい街並みをたのしみながら、旅の締めくくりに。',
                img: {
                  src: '/assets/images/todo/example/img_example12.png',
                  alt: '',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="c_uniqueTitle">
        <h1 className="main" style={{ fontSize: isSp ? '4rem' : '5.5rem' }}>
          Have a nice time !
        </h1>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
